.ComingSoon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.ComingSoon img {
  width:130px;
  object-fit: contain;
}
.ComingSoon h6 {
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  margin: 15px 0px;
}
.ComingSoon p {
  font-size: 15px;
  font-weight: 400;
  color: #8f92a1;
  text-align: center;
  margin-bottom: 5px;
}
